import React from 'react'
import './style.css'

export default () => (
  <div className='footer'>
    <div id='footer-middler'>
      <p align='left' className='copyright'>
        &copy; 2012-2018 Appko Inc.
        <br />
        All Rights Reserved.
      </p>
      <div className='footer-social'>
        <p align='right'>
          Follow us:
          <a href='https://twitter.com/appkoteam'>
            <img src='/_ima/social-twitter.png' alt='Twitter' />
          </a>
          <a href='https://www.facebook.com/pages/Appko-Inc/667433059934640'>
            <img src='/_ima/social-facebook.png' alt='Facebook' />
          </a>
          <a href='https://www.linkedin.com/company/appko'>
            <img src='/_ima/social-linkedin.png' alt='Linkedin' />
          </a>
        </p>
      </div>
    </div>
  </div>
)
