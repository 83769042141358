import React from 'react'
import { hydrate, render } from 'react-dom'
import Routes from './routes'
import registerServiceWorker, { unregister } from './registerServiceWorker'

const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(<Routes />, rootElement)
} else {
  render(<Routes />, rootElement)
}
unregister()
// registerServiceWorker()
