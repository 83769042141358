import React from 'react'
import { withRouter } from 'react-router-dom'
import Loadable from 'react-loadable'
import Modal from 'react-modal'
import AppLoader from '../PageLoader'

const OurHistory = Loadable({
  loader: () => import('../OurHistory'),
  loading: AppLoader,
})
const AppkoAndCIS = Loadable({
  loader: () => import('../AppkoAndCIS'),
  loading: AppLoader,
})
const AppkoAccolade = Loadable({
  loader: () => import('../AppkoAccolade'),
  loading: AppLoader,
})
Modal.setAppElement('#root')

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '960px',
    border: '1px solid #5c5c85',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const getPopUpContent = (location) => {
  const { search } = location
  let component = null
  if (search === '?our-story') {
    component = OurHistory
  }
  if (search === '?Appko_and_CIS') {
    component = AppkoAndCIS
  }
  if (search === '?Appko_Accolade') {
    component = AppkoAccolade
  }
  return component
}

const PopUpWindow = ({ location, history }) => {
  const handleClose = () => history.push(location.path)
  const PopUpContent = getPopUpContent(location)
  return (
    <Modal
      isOpen={!!PopUpContent}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel='modal-content'
    >
      <div
        className='modal-content-wrapper'
        onClick={handleClose}
        style={{
          width: '100%',
          height: '100%',
        }}
        role='presentation'
      >
        {PopUpContent && <PopUpContent />}
      </div>
    </Modal>
  )
}

export default withRouter(PopUpWindow)
