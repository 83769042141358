import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga'
import createHistory from 'history/createBrowserHistory'
import Loadable from 'react-loadable'
import Helmet from 'react-helmet'
import AppLoader from '../common/PageLoader'
import Footer from '../common/Footer'
import Header from '../common/Header'
import PartnerCustomers from '../common/PartnerCustomers'
import PageNotFound from '../common/PageNotFound'
import Submenu from './Cloud/Submenu'
import PopUpWindow from '../common/PopUpWindow'
import './style.css'

const snap = navigator.userAgent !== 'ReactSnap'
const production = process.env.NODE_ENV === 'production'
if (production && snap) { ReactGA.initialize('UA-67371329-1') }

export const history = createHistory({ basename: '/' })
const fireTracking = () => ReactGA.pageview(window.location.hash)

const Home = Loadable({
  loader: () => import('./Home'),
  loading: AppLoader,
})

const Cloud = Loadable({
  loader: () => import('./Cloud'),
  loading: AppLoader,
})

const Portfolio = Loadable({
  loader: () => import('./Portfolio'),
  loading: AppLoader,
})

const About = Loadable({
  loader: () => import('./About'),
  loading: AppLoader,
})

const Contact = Loadable({
  loader: () => import('./Contact'),
  loading: AppLoader,
})

const Development = Loadable({
  loader: () => import('./Development'),
  loading: AppLoader,
})


export default () => (
  <Router onUpdate={fireTracking} history={history}>
    <div className='app-layout'>
      <Helmet
        defaultTitle='Appko'
        titleTemplate='Appko - %s'
      />
      <Header />
      <PopUpWindow />
      <Switch>
        <Route exact path='/cloud' component={Submenu} />
      </Switch>
      <div className='content'>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/cloud' component={Cloud} />
          <Route exact path='/portfolio' component={Portfolio} />
          <Route exact path='/about' component={About} />
          <Route exact path='/contactus' component={Contact} />
          <Route exact path='/development' component={Development} />
          <Route path='*' component={PageNotFound} />
        </Switch>
        <PartnerCustomers />
      </div>
      <Footer />
    </div>
  </Router>
)
