import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import './style.css'

export default () => (
  <div className='header'>
    <Link to='/'>
      <img src='/_ima/appko.png' alt='Appko logo' className='left' />
    </Link>
    <ul className='navigation'>
      <li><NavLink to='/contactus' exact activeClassName='navon'>Contact</NavLink></li>
      <li><NavLink to='/about' exact activeClassName='navon'>About</NavLink></li>
      <li><NavLink to='/portfolio' exact activeClassName='navon'>Portfolio</NavLink></li>
      <li><NavLink to='/cloud' exact activeClassName='navon'>Cloud</NavLink></li>
      <li><NavLink to='/development' exact activeClassName='navon'>Development</NavLink></li>
      <li><NavLink to='/' exact activeClassName='navon'>Home</NavLink></li>
    </ul>
  </div>
)
