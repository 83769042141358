import React from 'react'
import './style.css'

export default () => (
  <div className='submenu' id='submenu'>
    <hr />
    <ul className='subnavigation'>
      <li><a href='#cloud'>Cloud Development</a></li>
      <li><a href='#openstack'>OpenStack</a></li>
      <li><a href='#vmware'>VMware</a></li>
      <li><a href='#submenu'>Cisco/Tidal Technologies</a></li>
    </ul>
  </div>
)
