import React from 'react'
import './style.css'

export default () => (
  <div id='partners-customers'>
    <div id='partners-customers-left'>
      <div className='image-wrapper'>
        <img src='/_ima/Technology_for_Services_Page.png' alt='Technology' />
      </div>
    </div>

    <div id='partners-customers-right'>
      <div className='image-wrapper'>
        <img src='/_ima/Customers_for_Services_page.png' alt='Customers' />
      </div>
    </div>
  </div>
)
